<template>
  <App
    title="绑定用户"
    @click-left="onNavBarClickLeft"
    left-arrow
    :showFooterTabBar="true">
    <div class="search">
      <van-search v-model="keyword" placeholder="请输入手机号" @search="onSearch" class="van-search">
        <!-- <template #action>
          <div>搜索
            <Button type="primary" size="mini" @click="onSearch"></Button>
            <Button type="primary" size="mini" @click="binding">绑定</Button>
          </div>
        </template> -->
      </van-search>
      <Button type="primary" size="small" @click="onSearch" style="margin: 0 5px;">搜索</Button>
      <Button type="primary" size="small" @click="binding">绑定</Button>
    </div>
    <PullRefresh @refresh="onRefresh" v-model="loading">
      <List
        v-model="loading"
        :finished="finished"
        :error.sync="error"
        :finished-text="total === 0 ? '暂无数据!' : 'No more items.!'"
        error-text="数据加载失败! 点击重试"
        @load="Onload">
        <RadioGroup v-model="accountid">
          <Radio class="account" v-for="$e in accountList" :key="$e.eosAccountId" :name="$e.eosAccountId">
            <div>
              <div>姓名：{{ $e.name }}</div>
              <div>性别：{{ $e.gender ? ($e.gender === '0' ? '男' : '女') : '' }}</div>
              <div>电话：{{ $e.phone }}</div>
              <!-- <Button type="primary" size="mini" @click.stop="conversation($e.eosAccountId)">聊天</Button> -->
            </div>
          </Radio>
        </RadioGroup>
      </List>
    </PullRefresh>
  </App>
</template>

<script>
import { RadioGroup, Radio, PullRefresh, Toast, List, Search, Button } from 'vant'
import { wxConfig, wxAgentConfig, wxDoChat, wxgetCurExternalContact } from '@/utils/wechart'
import { accountList, wchatbinding, wchatinfo } from '@/api/workwchat'

export default {
  components: { Radio, RadioGroup, PullRefresh, List, [Search.name]: Search, Button },
  data () {
    return {
      loading: false,
      accountid: '',
      page: 1,
      size: 10,
      total: 0,
      accountList: [],
      finished: false,
      error: false,
      keyword: '',
      userid: ''
    }
  },
  mounted () {
    this.getConfig()
    this.getAccountList()
  },
  methods: {
    async getConfig () {
      const arrHref = document.location.href.split('#')
      await wxConfig(['selectExternalContact'], encodeURIComponent(arrHref[0]))
      await wxAgentConfig(['getCurExternalContact'], encodeURIComponent(arrHref[0]))
      this.userid = await wxgetCurExternalContact()
    },
    async binding () {
      if (!this.accountid) return Toast('请选择绑定客户')
      Toast.loading({
        message: '绑定中...',
        forbidClick: true,
        loadingType: 'spinner',
        overlay: true,
        duration: 0
      })
      if (!this.userid) return Toast('请在聊天工具栏进入应用绑定客户')
      const res = await wchatbinding({
        userid: this.userid,
        accountid: this.accountid
      })
      Toast.clear()
      if (res.success) {
        Toast.success('绑定成功')
        setTimeout(() => {
          this.onNavBarClickLeft()
        }, 1500)
      } else {
        Toast(res.msg)
      }
    },
    // 返回
    onNavBarClickLeft () {
      this.$router.go(-1)
    },
    // 下拉刷新
    onRefresh () {
      this.page = 1
      this.finished = false
      this.accountList = []
      this.total = 0
      this.getAccountList()
    },
    // 加载更多资源
    Onload () {
      this.getAccountList()
    },
    async getAccountList () {
      if (this.finished) return
      Toast.loading({
        message: 'Loading...',
        forbidClick: true,
        loadingType: 'spinner',
        overlay: true
      })
      const res = await accountList({
        page: this.page,
        size: this.size,
        phone: this.keyword
      })
      Toast.clear()
      if (res.success) {
        this.total = res.data.total
        this.accountList = [...this.accountList, ...res.data.records]
        if (this.accountList.length === res.data.total) {
          this.finished = true
        } else {
          this.page++
        }
        this.error = false
        this.loading = false
      } else {
        this.error = true
        this.loading = false
        Toast(res.msg)
      }
    },
    onSearch () {
      this.onRefresh()
    },
    async conversation (accountId) {
      wxDoChat(accountId)
    }
  }
}
</script>

<style lang="less" scoped>
@import '@/style/common.less';
@import '@/style/vant-patch.less';
@import '@/style/var.less';
.search {
  padding: @padding-lg @padding-xl;
  background-color: @white;
  display: flex;
  align-items: center;
  .van-search {
    flex: 1;
  }
}
.account {
  background-color: @white;
  font-size  : @line-height-lg;
  padding: @padding-xl;
  border-bottom: 1px solid @gray-5;
}
</style>
